import React, { FC } from "react";

export const PrivacyPolicyInfo: FC = () => (
  <div className="l-full-mobile l-dbl-push-bottom text-12">
    ANIXE Polska Sp. z o.o. needs the data you provided above for the purpose of
    preparing ANIXE’s Resfinity platform Demo catered to your needs. Detailed
    information on the scope and manner of data processing can be found in the{" "}
    <a className="text-underline" href="/privacy">
      Data Protection Declaration.
    </a>
  </div>
);
